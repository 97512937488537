import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
const firebaseConfig = {
   apiKey: "AIzaSyAKOj34WRd6wryK_qdQWHI4s19Y3U9rQFU",
   authDomain: "health-8ba11.firebaseapp.com",
   databaseURL: "YOUR_API_KEY",
   projectId: "health-8ba11",
   storageBucket: "health-8ba11.appspot.com",
   messagingSenderId: "305508469988",
   appId: "1:305508469988:web:fd2700d2f371c81fc730f1",
   measurementId: "G-VWYVKJYPHL"
};
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
export { db };